<template>
  <div class="index">
    <router-view class="page"></router-view>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
.index {
  display: flex;
  flex-direction: column;
}
.page {
  flex: 1;
}
</style>